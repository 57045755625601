'use client'
import React, { FC, useState, useEffect } from "react";
import "react-dates/initialize";
import { DateRangePicker, DayPickerRangeController } from 'react-dates';
import moment from "moment";
import "moment/locale/ru";
moment.locale('ru');
import { helpers } from "../../../../services/_helpers/Helpers";
import useResize from "../../../../hooks/useResize";
import { ReactSVG } from 'react-svg';

interface IProps {
  checkIn?: any
  checkOut?: any
  lg?: number
};


const CustomDateRange: FC<IProps> = (props) => {
  const { isMobile } = useResize();
  const [focusedInput, setFocusedInput] = useState(null);

  const [startDate, setStartDate] = useState<any>(
    props.checkIn ? moment(props.checkIn, helpers.constant.formatDate) : moment().add(1, "days")
  );
  const [endDate, setEndDate] = useState<any>(
    props.checkOut ? moment(props.checkOut, helpers.constant.formatDate) : moment().add(5, "days")
  );
  const [night, setNight] = useState<any>(
    startDate && endDate ? endDate.diff(startDate, 'days') : 0
  );
  return (
    <div
      // className={`${classes.form_group} ${classes.dateBox} h-100 no-overflow`}
      className={`h-100 no-overflow`}
    >
      <label className="form-label fw-normal text-dark">Даты заезда и выезда</label>
      <DateRangePicker
        // autoFocus
        // isDayPickerFocused={true}
        hideKeyboardShortcutsPanel={true}
        renderDayContents={(date, action) => {
          if (startDate && !endDate && action.has('hovered')) {
            setNight(date.diff(startDate, 'days'))
          }
          return (
            <div>
              {date.format('DD')}
            </div>
          );
        }}
        // noBorder
        readOnly
        // customInputIcon={<ReactSVG className={classes.calendar} src={"/icons/calendar-icon.svg"} />}
        startDate={startDate}
        startDateId="checkIn"
        endDate={endDate}
        orientation={isMobile ? "vertical" : "horizontal"}
        endDateId="checkOut"
        onDatesChange={({ startDate, endDate }) => {

          let getNight = 0;
          if (startDate && endDate) {
            getNight = endDate.diff(startDate, 'days');
          }
          setNight(getNight);
          setStartDate(startDate ? moment(startDate) : null);
          setEndDate(endDate ? moment(endDate) : null);
        }}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput)
        }}
        showDefaultInputIcon
        inputIconPosition="after"
        small={true}
        displayFormat={helpers.constant.formatDate}
        startDatePlaceholderText="Заезд"
        endDatePlaceholderText="выезд"
        //isOutsideRange={(date) => date.isBefore(startDateLimit) || moment().endOf('day').isBefore(date)}
        minimumNights={0}
        calendarInfoPosition="bottom"
        renderCalendarInfo={(day) => <div className={`${isMobile ? 'my-2' : 'my-3'} h-100`}><p className="py-3 fw-bold text-center">Выбрано ночей: {night}</p></div>}
      />
      {/* <input type="hidden" name='checkIn' value={moment(startDate).format(helpers.constant.formatDate)} />
      <input type="hidden" name='checkOut' value={moment(endDate).format(helpers.constant.formatDate)} /> */}
      <input type="hidden" name="night" value={night} />
    </div>
  );
};

export default CustomDateRange;
