import React, { Component } from "react";

import Helmet from 'react-helmet';

import classes from "./InputNumber.module.css";

class InputNumber extends Component {
    constructor(props) {
        super(props);
        this.childrenAge = this.childrenAge.bind(this);
        this.pushChildrenAgeValue = this.pushChildrenAgeValue.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handlerChange = this.handlerChange.bind(this);

        let ChildrenAgeClassName = [];
        if (props.ChildrenAgeClassName) {
            ChildrenAgeClassName = [
                props.ChildrenAgeClassName
            ];
        } else {
            ChildrenAgeClassName = [
                classes.childrenAgeBlock,
                "py-2",
                "px-2"
            ];
        }

        this.state = {
            handleClickOutside: this.props.handleClickDisable ? false : true,
            ChildrenLabelStyle: props.ChildrenLabelStyle ? props.ChildrenLabelStyle : {},
            ChildrenAgeBlockStyle: props.ChildrenAgeBlockStyle ? props.ChildrenAgeBlockStyle : {},
            ChildrenAgeDivClassName: props.ChildrenAgeDivClassName ? props.ChildrenAgeDivClassName : "text-light",
            ChildrenAgeClassName: ChildrenAgeClassName.join(' '),
            labelStyle: props.labelStyle ? props.labelStyle : {},
            labelClass: props.labelClass ? props.labelClass : "",
            value: this.props.value ? this.props.value : 0,
            child_age: props.child_age ? props.child_age : false,
            childrenAgeValue: props.ages ? props.ages : [],
            divStyle: props.divStyle ? props.divStyle : {},
            showAgeBlock: false,
        }
    }

    componentDidMount() {
        if (this.state.handleClickOutside) {
            document.addEventListener('mousedown', this.handleClickOutside);
        }
    }

    componentWillUnmount() {
        if (this.state.handleClickOutside) {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState(
                { showAgeBlock: !this.state.showAgeBlock }
            );
        }
    }
    handlerChange(event) {
        this.setState({
            value: event.target.value
        })
    }
    decrease = async (e) => {
        e.preventDefault();
        if (this.state.value == 0) {
            return false;
        }
        let childrenAgeValue = this.state.childrenAgeValue;
        let newchildrenAgeValue = childrenAgeValue.slice(0, -1);
        await this.setState({
            value: parseInt(this.state.value) - 1,
            childrenAgeValue: newchildrenAgeValue,
            showAgeBlock: true
        });
        if (this.props.ChangeNumberHandler) {
            this.props.ChangeNumberHandler(this.props.name, this.state.value);
        }
        if (this.props.ChangeAgeValue) {
            this.props.ChangeAgeValue(this.state.childrenAgeValue);
        }
    }

    increase = async (e) => {
        e.preventDefault();
        await this.setState(prevState => ({
            childrenAgeValue: [...prevState.childrenAgeValue, 0],
            value: parseInt(this.state.value) + 1,
            showAgeBlock: true
        }));


        if (this.props.ChangeNumberHandler) {
            this.props.ChangeNumberHandler(this.props.name, this.state.value);
        }
        if (this.props.ChangeAgeValue) {
            this.props.ChangeAgeValue(this.state.childrenAgeValue);
        }
    }

    // handlerChange = async (e) => {
    //     e.preventDefault();
    //     await this.setState(prevState => ({
    //         childrenAgeValue: [...prevState.childrenAgeValue, 0],
    //         value: this.state.value + 1,
    //         showAgeBlock: true
    //     }));
    //
    //
    //     if(this.props.ChangeNumberHandler){
    //         this.props.ChangeNumberHandler(this.props.name,this.state.value);
    //     }
    //     if(this.props.ChangeAgeValue){
    //         this.props.ChangeAgeValue(this.state.childrenAgeValue);
    //     }
    // }
    pushChildrenAgeValue = async (event) => {
        let dataId = event.target.getAttribute("data-id") * 1;
        let value = event.target.value;
        let { childrenAgeValue } = this.state;
        childrenAgeValue[dataId] = parseInt(value);
        await this.setState({
            childrenAgeValue,
        });
        if (this.props.ChangeAgeValue) {
            this.props.ChangeAgeValue(this.state.childrenAgeValue);
        }
    }
    childrenAge = () => {
        const { childrenAgeValue } = this.state;
        let age = [];
        let options = [
            { "value": "до года", "selected": false },
            { "value": "1 год", "selected": false },
            { "value": "2 года", "selected": false },
            { "value": "3 года", "selected": false },
            { "value": "4 года", "selected": false },
            { "value": "5 лет", "selected": false },
            { "value": "6 лет", "selected": false },
            { "value": "7 лет", "selected": false },
            { "value": "8 лет", "selected": false },
            { "value": "9 лет", "selected": false },
            { "value": "10 лет", "selected": false },
            { "value": "11 лет", "selected": false },
            { "value": "12 лет", "selected": false },
            { "value": "13 лет", "selected": false },
            { "value": "14 лет", "selected": false },
            { "value": "15 лет", "selected": false },
            { "value": "16 лет", "selected": false },
            { "value": "17 лет", "selected": false },
        ];
        for (let i = 0; i < this.state.value; i++) {
            let select = 0;
            if (childrenAgeValue.length > 0) {
                if (childrenAgeValue[i] === undefined) {
                    select = 0;
                } else {
                    select = childrenAgeValue[i];
                }
            } else {
                select = 0;
            }
            age.push(
                <div key={"age_children-" + i}>
                    <select value={select} data-id={i} onChange={this.pushChildrenAgeValue} className="browser-default custom-select py-2 my-1 w-100 rounded px-2" key={i}>
                        {options.map((opt, idx) => {
                            return (
                                <option key={`opt-${i}-${idx}`} value={idx}>{opt.value}</option>
                            )
                        })}
                    </select>
                </div>
            )
        }
        return (
            <div ref={this.setWrapperRef}
                className={this.state.ChildrenAgeClassName}
                style={this.state.showAgeBlock ? this.state.ChildrenAgeBlockStyle : { display: "none" }}
            >
                <label className={this.state.ChildrenAgeDivClassName}>Возрасты детей</label>
                {age}
            </div>
        );

    }
    render() {
        return (
            <React.Fragment>
                <label className={this.state.labelClass} style={this.state.labelStyle} htmlFor={this.props.name}>{this.props.label}</label>
                <div style={{ "width": "fit-content" }}>
                    <div className="def-number-input number-input" style={this.state.divStyle}>
                        <button onClick={this.decrease} className="minus"></button>
                        <input className="quantity"
                            name={this.props.name}
                            value={this.state.value}
                            type="number"
                            min="0"
                            max="20"
                            onChange={this.handlerChange}
                        />
                        <button onClick={this.increase} className="plus"></button>
                    </div>
                </div>
                {this.state.child_age && this.state.value > 0 ? (
                    this.childrenAge()
                ) : null}
                {this.state.child_age && <input type="hidden" name="ages" value={this.state.childrenAgeValue} />}

            </React.Fragment>
        );
    }
}

export default InputNumber;

