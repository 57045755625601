"use client"; // Указываем, что это клиентский компонент

import { useEffect } from "react";
import { usePathname } from "next/navigation";

export default function ScrollToSection() {
    const pathname = usePathname();

    useEffect(() => {
        const handleComplete = () => {
            //@ts-ignore
            const hash = pathname.split("#")[1];
            if (hash) {
                const tryScrollToSection = (attempts = 100) => {
                    const section = document.getElementById(hash);
                    if (section) {
                        section.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                            inline: "nearest",
                        });
                    } else if (attempts > 0) {
                        setTimeout(() => tryScrollToSection(attempts - 1), 100);
                    }
                };

                tryScrollToSection();
            }
        };

        if (document.readyState === "complete") {
            handleComplete();
        } else {
            window.addEventListener("load", handleComplete);
        }

        return () => {
            window.removeEventListener("load", handleComplete);
        };
    }, [pathname]);

    return null; // Компонент не рендерит ничего визуально
}
