//@ts-nocheck
"use client"
import React, { FC, useState, useEffect } from "react";
import Link from 'next/link';
import { ReactSVG } from 'react-svg'
import {
    Navbar,
    Nav
} from "react-bootstrap";
import classes from "./MainMenu.module.css";
import { api } from "../../../services/api/Api";
import { useLandingContext } from "../../../context/LandingProvider";
import useResize from "../../../hooks/useResize";




interface IProps {
    mobile?: boolean;
};

const MainMenu: FC<IProps> = ({ mobile = false }) => {
    const { theme, menu } = useLandingContext();
    const [collapse, setCollapse] = useState<boolean>(false);
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    // useEffect(() => {
    //     async function fetchData() {
    //         const ids = theme.menu && Array.isArray(theme.menu) ? theme.menu.map(menu => menu._id) : [];
    //         //const indexedIds = theme.menu && Array.isArray(theme.menu) ? theme.menu.map((id, index) => ({ id, index })) : [];
    //         let query = {
    //             filter: { _id: { $in: ids }, publish: true },
    //             fields: { title: 1, title_slug: 1, url: 1, type: 1 }
    //         };
    //         const result = await api.cms.get("pages", query);
    //         const sortedResult = ids.map(item => result.find(resultItem => resultItem._id == item));
    //         if (sortedResult) {
    //             setData(sortedResult);
    //             setIsLoading(false);
    //         }
    //     }
    //     fetchData();
    // }, []);
    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            style={mobile ? { padding: ".5rem 1rem", backgroundColor: `${theme.mainColor}` } : null}
            className={mobile ? "d-flex d-lg-none" : "d-none d-lg-block"}
        >
            <Navbar.Brand className="text-light d-lg-none" href="#home">Меню</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className={`${classes.navbarToggle} navbar-light float-end`} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`navbar-nav justify-content-around ${classes.nav}`} style={{ margin: "0 auto" }}>
                    <Link href="/" as={"/"} passHref>
                        Главная
                    </Link>
                    {Array.isArray(menu) && menu.map((link, index) => {
                        if (!link || !link.title_slug) return null;
                        let slug = link.title_slug;
                        let url = link.url;
                        switch (link.type) {
                            case 'rooms':
                                slug = 'rooms';
                                break;
                            case 'services':
                                slug = 'services';
                                break;
                            case 'therapy':
                                slug = 'therapy';
                                break;
                        }
                        return (
                            <Link
                                key={`mainMenu-${index}`}
                                href={`/${url ? url : slug}`}
                                as={`/${url ? url : slug}`}
                                passHref
                            >
                                {link.title}
                            </Link>
                        )
                    })}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
export default MainMenu;

