//@ts-nocheck
"use client"
import React, { useEffect, useMemo, useState } from 'react';
import { usePathname } from 'next/navigation'
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { cmsApi } from "../api/cms";
import PreLoading from "../components/mains/PreLoading";
import Utils from "../../utils/all";
import classes from "./Related.module.css";
import Link from 'next/link';
import { useLandingContext } from '../../context/LandingProvider';
import { api } from '../../services/api/Api';
import { ImageSrc } from '../../componentsNew/elements/ImageSrc';


const Related = () => {
    const path = usePathname();
    if (path == '/hotels') return null;
    const { theme } = useLandingContext();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const hotelIds = useMemo(() => {
        if (theme.hotels) {
            return theme.hotels.map((hotel) => hotel._id);
        }
        return [];
    }, [theme.hotels]);

    useEffect(() => {
        async function fetchData() {
            if (hotelIds.length > 0) {
                const query = {
                    filter: {
                        _id: { $in: hotelIds },
                    },
                };
                const fetchedItems = await api.cms.get("hotels", query, false);
                if (fetchedItems) {
                    setItems(fetchedItems);
                    setIsLoading(false);
                }
            }
        }
        fetchData();
    }, [hotelIds]);

    if (!Array.isArray(items) || isLoading) return null;
    return (
        <Container suppressHydrationWarning>
            <Row className="my-4">
                <Col xs={12}><h3 className='text-center'>ДРУГИЕ ОТЕЛИ СЕТИ "АТЕЛИКА" И "ДИВНЫЙ МИР"</h3></Col>
            </Row>
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={9000}
                autoPlay={true}
                centerMode={false}
                className=""
                containerClass="container-with-dots py-3"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 2,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {items.map((item, idx) => {
                    return (
                        <div className={classes.itemRelBox} key={item._id}>
                            {item.image && <ImageSrc cdn={false} storage={true} asset={item.image} />}
                            <a className={classes.relTitle} href={item.web} target="_blank">{item.title}</a>
                        </div>
                    )
                })}
            </Carousel>
            <Row className="my-4">
                <div className="col-12 text-center">
                    <Link
                        legacyBehavior
                        href={`/hotels`}
                        as={`/hotels`}
                        passHref
                    >
                        <a className="btn btn-info" target="_blank">ПОСМОТРЕТЬ ВСЕ</a>
                    </Link>
                </div>
            </Row>
        </Container>
    )
}
export default Related;

