//@ts-nocheck
"use client"
import React, { FC, useState, useEffect } from "react";
import { ReactSVG } from 'react-svg';
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Col,
    Container,
    Row,
    Button,
    Modal,
    FormGroup,
    FormLabel,
    FormControl,
    Alert,
    Spinner
} from "react-bootstrap";



import classes from "./Calculate.module.css";
import AlertInfo from "../ui/alert/AlertInfo";
import Utils from "../../../utils/all";
import InputNumber from "../forms/inputs/InputNumber";
import MaskedInput from 'react-text-mask';
import { helpers } from '../../../services/_helpers/Helpers';
import { useLandingContext } from "../../../context/LandingProvider";
import CustomDateRange from "../../../componentsNew/form/inputs/dateRange/CustomDateRange";
import { api } from "../../../services/api/Api";


interface IProps { };

const Calculate: FC<IProps> = (props) => {
    const { landing, notify, theme } = useLandingContext();
    const [step, setStep] = useState(1);
    const [modal, setModal] = useState(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [isSend, setIsSend] = useState<boolean>(false);
    const [alertInfo, setAlertInfo] = useState<string>("");





    useEffect(() => {
        if (step == 3) setDisabled(false);
    }, [step])

    const handleSubmit = async (event, values) => {
        event.preventDefault();

        setDisabled(true);
        setIsSend(true);
        await new Promise((r) => setTimeout(r, 500));

        const startDate = event.target.checkIn.value;
        const endDate = event.target.checkOut.value;
        const adults = event.target.adults.value;
        const childrens = event.target.childrens.value;
        const ages = event.target.ages.value;
        const rooms = event.target.rooms.value;
        const message = values.message;
        const name = values.name;
        const email = values.email;
        const phone = values.phone;

        const alertText = notify.formCalc && name ? Utils.createStringFromTemplate(notify.formCalc, { fName: name }) : "";

        const query = {
            date: `${startDate} - ${endDate}`,
            adults: adults,
            childrens: childrens,
            ages: ages,
            rooms: rooms,
            message: message,
            name: name,
            email: email,
            phone: phone,
            site: {
                _id: landing._id,
                link: "sites",
                display: landing.domain
            }
        }

        const result = await api.cms.save("calc", query);

        if (result) {
            const html = `
            <p><strong>Заезд: </strong>${startDate}</p>
            <p><strong>Выезд: </strong>${endDate}</p>
            <p><strong>Взрослых: </strong>${adults}</p>
            <p><strong>Детей: </strong>${childrens}</p>
            <p><strong>Возраст: </strong>${ages}</p>
            <p><strong>Номеров: </strong>${rooms}</p>
            <p><strong>Сообщение: </strong>${message}</p>
            <p><strong>Имя: </strong>${name}</p>
            <p><strong>Email: </strong>${email}</p>
            <p><strong>Телефон: </strong>${phone}</p>
            <p><strong>Сайт: </strong>${landing.domain}</p>
            `;
            const mail = {
                to: [theme.email, 'testcrm@bag-travels.ru'],
                subject: `Запрос расчета - ${landing.domain}`,
                html: html,
            };
            await api.mail.send(landing.domain, mail);

            setIsSend(false);
            setAlertInfo(alertText);
            setTimeout(() => {
                setModal(false)
            }, 12000);
        }
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required("Заполните это поле"),
        email: Yup.string()
            .email("Введен недействительный адрес электронной почты")
            .required("Заполните это поле"),
        phone: Yup.string()
            .required("Заполните это поле")
            .matches(/^\+7\s?\(?\d{3}\)?\s?\d{3}[-\s]?\d{2}[-\s]?\d{2}$/, "Введите корректный номер"),
        // phone: Yup.string()
        //     .matches(/^\+7\(\d{3}\) \d{3}-\d{4}$/, "Введен некорректный номер телефона, пример: +7(111) 111-1111")
        //     .required("Заполните это поле"),
    });

    return (
        <React.Fragment>
            <ReactSVG
                className={`${modal && 'd-none'} ${classes.icon} no-print`} src={`/comment.svg`}
                onClick={() => setModal(!modal)}
            />
            <Modal show={modal} onHide={() => setModal(!modal)} centered>
                <Modal.Header closeButton={true}>
                    <Modal.Title>Запросить расчет</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={12}>
                            Здравствуйте, я помогу вам определиться с отдыхом в {theme?.headerTitle ? theme.headerTitle : ""}
                            <br />
                            <p>Когда бы Вы хотели поехать?</p>
                        </Col>
                    </Row>
                    <Formik
                        initialValues={{
                            name: "",
                            email: "",
                            phone: "+7",
                            message: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={() => { }}
                    >
                        {({ values, errors, handleChange, handleBlur, touched, isSubmitting, setFieldTouched, setFieldValue }) => (
                            <Form onSubmit={(e) => handleSubmit(e, values)}>
                                <Row>
                                    {landing.screenMobile
                                        ?
                                        <Col xs="12" lg="3" sm="12" className="mb-3">
                                            <div className="form-group required">
                                                <CustomDateRange
                                                    label={{
                                                        className: "form-label text-dark",
                                                        status: true,
                                                        text: "Даты заезда и выезда"
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        :
                                        <Col xs="12" lg="12" sm="12" className="mb-3">
                                            <div className="form-group required">
                                                <CustomDateRange
                                                    label={{
                                                        className: "form-label text-dark",
                                                        status: true,
                                                        text: "Даты заезда и выезда"
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    }
                                    <React.Fragment>
                                        <Col xs="12" className="mb-3">

                                            <div className="form-group required">
                                                <InputNumber
                                                    name="adults"
                                                    label="Сколько будет взрослых?"
                                                    labelClass="text-dark form-label"
                                                    value={0}
                                                    ChangeNumberHandler={() => setStep(step + 1)}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs="12" className="mb-3">
                                            <div className="form-group">
                                                <InputNumber name="childrens"
                                                    label="Дети поедут с Вами?"
                                                    labelClass="text-dark form-label"
                                                    child_age
                                                    handleClickDisable
                                                    ChildrenAgeClassName={`${classes.ageBox} mt-3`}
                                                    ChildrenAgeDivClassName="text-dark form-label fw-bold"
                                                // ChangeNumberHandler={this.ChangeNumberHandler}
                                                // ChangeAgeValue={this.ChangeAgeValue}
                                                />
                                            </div>
                                        </Col>
                                    </React.Fragment>
                                    {step > 1 &&
                                        <React.Fragment>
                                            <Col xs="12" className="mb-3">
                                                <div className="form-group required">
                                                    <InputNumber
                                                        name="rooms"
                                                        label="Сколько Вам нужно номеров?"
                                                        labelClass="text-dark form-label"
                                                        value={0}
                                                        ChangeNumberHandler={() => setStep(step + 1)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="12" className="mb-3">
                                                <FormGroup className="mb-3">
                                                    <FormLabel
                                                        className="text-right text-dark form-label">
                                                        Примечание
                                                    </FormLabel>
                                                    <FormControl
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="message"
                                                        as="textarea" rows={3}>{values.message}</FormControl>
                                                </FormGroup>
                                            </Col>
                                        </React.Fragment>
                                    }

                                    {step > 2 &&
                                        <React.Fragment>
                                            <Col xs="12" className="mb-3">
                                                <p>
                                                    У меня готово подходящее предложение для Вас, сообщите свои
                                                    контактные данные и я сообщу подробную информацию:
                                                </p>
                                            </Col>
                                            < Col xs="12" className="mb-3">
                                                <FormGroup className="form-group mb-3 required">
                                                    <FormLabel
                                                        className="text-right text-dark control-label">
                                                        Ваше Имя
                                                    </FormLabel>
                                                    <FormControl
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required
                                                        size="sm" name="name" type="text"
                                                        value={values.name}
                                                        isValid={touched.name && !errors.name}
                                                        isInvalid={!!errors.name}
                                                    />
                                                    {touched.name && errors.name && (
                                                        <div className="error-message">{errors.name}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col xs="12" className="mb-3">
                                                <FormGroup className="form-group mb-3 required">
                                                    <FormLabel
                                                        className="text-right text-dark control-label">
                                                        Ваш Email
                                                    </FormLabel>
                                                    <FormControl
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        required
                                                        size="sm" name="email" type="text"
                                                        value={values.email}
                                                        isValid={touched.email && !errors.email}
                                                        isInvalid={!!errors.email}
                                                    />
                                                    {touched.email && errors.email && (
                                                        <div className="error-message">{errors.email}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>

                                            <Col xs="12" className="mb-3">
                                                <FormGroup className="form-group mb-3 required">
                                                    <FormLabel className="text-right text-dark control-label">
                                                        Ваш Телефон
                                                    </FormLabel>
                                                    <Field name="phone">
                                                        {({ field }) => (
                                                            <MaskedInput
                                                                {...field}
                                                                mask={helpers.form.phoneMask()}
                                                                placeholder="+7 (___) ___-__-__"
                                                                type="text"
                                                                required
                                                                className={`form-control ${touched.phone && errors.phone ? "is-invalid" : ""}`}
                                                                onChange={(e) => setFieldValue("phone", e.target.value)}
                                                                onBlur={() => setFieldTouched("phone", true)}
                                                                onInvalid={(e) => {
                                                                    e.preventDefault();
                                                                    setFieldTouched("phone", true);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                    {touched.phone && errors.phone && (
                                                        <div className="error-message">{errors.phone}</div>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </React.Fragment>
                                    }
                                    {alertInfo &&
                                        <Col xs={12}>
                                            <AlertInfo
                                                show
                                                hide
                                                type={notify.formCalcColor ? notify.formCalcColor : "success"}
                                                hideTime={11000}
                                            >
                                                {alertInfo}
                                            </AlertInfo>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={disabled}
                                        >
                                            {isSend &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }
                                            Отправить
                                        </Button>
                                    </Col>

                                    <Col>
                                        <Button
                                            variant="danger"
                                            onClick={() => setModal(false)}
                                        >
                                            Закрыть
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default Calculate;