//@ts-nocheck
"use client"
import React, { FC } from "react";
import Link from 'next/link';
import { Container, Row, Col } from "react-bootstrap";
import MainMenu from '../components/mains/MainMenu';
import BackCall from '../components/modals/BackCall';
import classes from "./Header.module.css";
import { helpers } from "../../services/_helpers/Helpers";

interface IProps {
    theme: any;
};

const Header: FC<IProps> = ({ theme }) => {
    const logoPosition = theme.lgPosition && theme.lgPosition == "right" ? "flex-row-reverse" : "flex-row";
    let logoClass = classes.logo;
    let logoSrc = helpers.constant.cms.storage + theme.logo.path;
    if (theme.lgPosition == "right") {
        logoClass = classes.bgLogo;
        // logoSrc = width <= 992 ? "/logo-no-text.png" : logoSrc;
    }
    return (
        <header className={classes.header} style={{ borderBottom: `2px solid ${theme.mainColor}` }}>
            <MainMenu mobile />
            <Container fluid className="py-3">
                <Row className={`justify-content-between  align-items-center`}>
                    <Col xs={12} lg="auto" className={`row d-flex align-items-center  ${logoPosition}`}>
                        {theme.logo &&
                            <Col
                                xs={theme.lgPosition == "right" ? 12 : 2}
                                lg={theme.lgPosition == "right" ? 4 : 2}
                            >
                                <Link href="/">
                                    <img className={logoClass}
                                        src={logoSrc}
                                    />
                                </Link>
                            </Col>
                        }
                        <Col
                            xs={theme.lgPosition == "right" ? 12 : 10}
                            lg={theme.lgPosition == "right" ? 8 : 10}
                        >
                            {theme.headerTitle &&
                                <p className={classes.name}><Link href="/">{theme.headerTitle}</Link></p>}
                            {theme.headerContent &&
                                <p className={`${classes.desc}`}>{theme.headerContent}</p>}
                            {theme.headerCity && <p className={`${classes.city}`}>{theme.headerCity}</p>}

                        </Col>
                        {/* <Col xs={12} className="d-sm-none">
                            {theme.headerContent &&
                                <p className={`d-xs-none ${classes.desc}`}>{theme.headerContent}</p>}
                            {theme.headerCity && <p className={`d-xs-none ${classes.city}`}>{theme.headerCity}</p>}
                        </Col> */}
                    </Col>
                    <Col xs={12} lg="auto" className={`d-flex flex-nowrap ${classes.phoneContainer}`}>
                        {theme.phones && <Phones phones={theme.phones} />}
                    </Col>
                    <Col xs={12} lg="auto" className={`${classes.backCall} m-auto`}>
                        <BackCall />
                    </Col>
                    {/* <Col className="my-2 text-center d-none d-xl-block my-2">
                    </Col> */}
                </Row>
            </Container>
            <MainMenu />
        </header>
    )
};

export default Header;

function Phones({ phones }) {
    let grid = 12 / phones.length;
    if (phones.length) {
        if (grid === 3) {
            let phone1 = phones[0].value;
            let phone2 = phones[1].value;
            let phone3 = phones[2].value;
            let phone4 = phones[3].value;
            return (
                <React.Fragment>
                    <Col xs="12" lg="auto" md="6">
                        {phone1.title && <p className={phone1?.mobStatus ? `text-weight-bold ${classes.phone}` : `d-none d-lg-block ${classes.phone}`}><a href={`tel:${phone1.title}`}><i className={classes.icon}></i><span className={classes.text}>{phone1.title}</span></a></p>}
                        {phone2.title && <p className={phone2?.mobStatus ? `text-weight-bold ${classes.phone}` : `d-none d-lg-block ${classes.phone}`}><a href={`tel:${phone2.title}`}><i className={classes.icon}></i>{phone2.title}</a></p>}
                    </Col>

                    <Col xs="12" lg="auto" md="6">
                        {phone3.title && <p className={phone3?.mobStatus ? `text-weight-bold ${classes.phone}` : `d-none d-lg-block ${classes.phone}`}><a href={`tel:${phone3.title}`}><i className={classes.icon}></i>{phone3.title}</a></p>}
                        {phone4.title && <p className={phone4?.mobStatus ? `text-weight-bold ${classes.phone}` : `d-none d-lg-block ${classes.phone}`}><a href={`tel:${phone4.title}`}><i className={classes.icon}></i>{phone4.title}</a></p>}
                    </Col>
                </React.Fragment>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }

}



